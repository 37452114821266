.select-error > [class*="-control"] {
  border: 1px solid red;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background: none;
}
.parrafo {
  white-space: pre;
}

.mapWrapper {
  width: 100%;
  min-height: 500px;
  position: relative;
} 
/*
.mapWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 1;
} */

/* div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  display: none;
} */

.suggestion-item {
  background-color: whitesmoke;
  cursor: pointer;
  padding: 10px 15px;
}

.suggestion-item--active {
  background-color: #FC6435;
  color: #fff;
  cursor: pointer;
  padding: 10px 15px;
}

.modal-content {
  border-radius: 0 !important;
  border: none !important;
}

.custom-spinner {
  color: #FC6435;
}

.image-user-create {
  max-width: 250px;
  height: 250px;
  object-fit: cover;
  object-position: center center;
}

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  background: none;
}

@media (max-width: 575px) {
  .mapWrapper {
    height: 300px;
    margin-top: 2rem;
  }
}

.btn-as, a {
  color: #FC6435;
}

.btn-as:hover, a:hover {
  color: #FC6435;
}

span.Select-aria-only {
  display: none;
}

.Select--multi .Select-value, .Select--multi .Select-value-icon:hover {
  color: #fff;
  background-color: #FC6435;
  border: none;
}

.Select--multi .Select-value-icon {
  border: none !important;
}

.formtype-filter {
  border: 1px solid #FC6435;
}

.formtype-filter .Select-control {
  border-radius: 0;
    border: none;
    color: #495057;
}

.Select-placeholder, :not(.Select--multi)>.Select-control .Select-value {
  color: #495057 !important;
}

.is-focused:not(.is-open)>.Select-control {
  box-shadow: none;
}

.Select-clear {
  color: red;
}

.Select-arrow-zone:hover>.Select-arrow, .is-open .Select-arrow {
  border-top-color: #FC6435;
}

.Select-arrow {
  border-color: #FC6435 transparent transparent
}

.list-group-menu .list-group-item { 
  padding: 0;
  background-color: transparent;
}

.list-group-menu .list-group-item > div:first-child {
  padding: 1em;
}

.list-group-menu .list-group-item .card {
  border: none;
  background-color: #2E3742;
  border-radius: 0;
} 

.list-group-menu .list-group-item .card-body {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  padding: 0;
}

.downloadIcon {
  border: none;
  background: none;
  margin-right: 15px;
}

@media (max-width: 1300px) {
  .locations-content {
    min-height: 100vh;
  } 
}